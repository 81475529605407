import { render, staticRenderFns } from "./news-add-or-update.vue?vue&type=template&id=c02c9e22"
import script from "./news-add-or-update.vue?vue&type=script&lang=js"
export * from "./news-add-or-update.vue?vue&type=script&lang=js"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports